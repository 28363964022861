.fetching-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}
.fetching-data h1 {
  margin: 0;
}
