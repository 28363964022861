.search-results, .profile-results {
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: baseline;
}

.search-game__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 378px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 90px;
    font-size: 1.5em;
}

.search-game, .profile-game {
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-right: 2%;
}

.search-game {
    /* width: 25%; */
    align-items: center;
}

.search-game__img {
    width: 302px;
    height: 222px;    
}



.profile-game img {
    width: 390px;
    height: 250px;
}

.search-game textarea {
    margin-top: 4%;
    height: 50px;
}

.search-result__add-game-flex {
    display: flex;
    justify-content: space-around;
}

.search-game .search__btn,
.custom-game__add-btn,
.search-result__lightning {
    background-color: #8E43FC;
    color: white;
    padding: 10px 25px;
    border-radius: 4px;
    box-shadow: 2px 3px 5px 2px #313030;
    margin-top: 4%;
    align-self: center;
}

.search-result__lightning {
    width: 83%;
    padding: 5px 12px;
}

.search-result__lightning:hover {
    cursor: pointer;
}

.search-game .search__btn:hover,
.custom-game__add-btn:hover {
    cursor: pointer;
    box-shadow: 0px 3px 12px 6px #313030;
    transition: 500ms;
}

.search-game__rating {
    display: flex;
    justify-content: space-around;
    margin-top: 7%;
    margin-bottom: 2%;
}

.search-game__status {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.search-results__pages {
    display: flex;
    flex-direction: row;
    width: 25%;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    position: fixed;
    bottom: 0;
    left: 37%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
}

.search-results__pages input {
    width: 50px;
    text-align: center;
}

.search-results__pages__nav {
    width: 12%;
}

.search-results__pages__nav:hover {
    cursor: pointer;
}

.search-result__added {
    background-color: #a9a9a9 !important;
    color: black !important;
    padding: 10px 25px;
    border-radius: 4px;
    box-shadow: 2px 3px 5px 2px #313030;
    margin-top: 4%;
}

.custom-game-container form{
    display: flex;
  justify-content: space-around;
  flex-direction: column;
  width: 25%;
  margin-left: auto;
  margin-right: auto;
}

.custom-game__field {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

#custom-game__game-type {
    display: flex;
    justify-content: space-around;
}

#custom-game__notif {
    display: none;
}