@font-face {
    font-family: vt323;
    src: url("../../assets/fonts/VT323-Regular.ttf");
}

.auth-nav__profile-img-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
}

#auth-nav__profile__name {
    margin-right: 1.5%    
}

#auth-nav__profile-img {
    width: 100px;
}

.dropdown {
    margin-right: 1.5%;
    margin-bottom: 0.25%;
}

.dropdown-menu {
    z-index: 9999;
}

.dropdown a {
    text-decoration: none;
}

.dropdown-link {
    color: black;
}

.auth-nav {
    display: flex;
    justify-content: space-around;
    background-color: #8E43FC;
    padding: 15px 0px;
}

.auth-nav__link {
    font-family: vt323;
    color: white;
    text-decoration: none;
    font-size: 1.5em;
}

.auth-nav__link:hover {
    text-decoration: underline;
}

p.auth-nav__link {
    margin: 0;
}

p.auth-nav__link:hover {
    cursor: pointer;
}