.user-game {
    display: flex;
  flex-direction: column;
  /* width: 30%; */
  align-items: center;
}

.user-game__search-game {
    display: flex;
    flex-direction: column;
    width: 30%;
}
.user-game__img:hover {
    cursor: pointer;
}

.user-game__img img {
    width: 100%;
    height: 250px;
}

.user-game__date-container {
    display: flex;
    justify-content: space-around;
    align-self: center;
    width: 100%;
}

.user-game__date-container label {
    margin: 15x 10px;
}

.user-game__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.user-game__title h2 {
    width: 378px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 90px;
    margin-left: auto;
    margin-right: auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
}

.user-game__rating {
    display: flex;
    justify-content: space-around;
}

.user-game__summary {
    margin-right: auto;
    margin-left: auto;
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    /* white-space: nowrap; */
    display: -webkit-box;
    max-width: 300px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.user-game__rating {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.user-game__rating__red {
    color: rgba(189, 10, 10, 0.942);
    font-weight: bolder;
}

.user-game__rating__yellow {
    color: rgb(215, 215, 12);
    font-weight: bolder;
}

.user-game__rating__green {
    color: rgb(10, 109, 10);
    font-weight: bolder
}

.user-game__status-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.user-game__readmore {
    color: #0e15e1;
}

.user-game__readmore:hover {
    cursor: pointer;
    text-decoration: underline;
}

#user-game__modal {
    background-color: rgba(0, 0, 0, 0.85);
    height: 78vh;
    width: 73%;
    position: fixed;
    z-index: 9999999;
    top: 12%;
    left: 14%;
}

.user-game__modal__close:hover {
    cursor: pointer;
}

.user-game__modal__close {
    color: white;
    position: absolute;
    top: 0%;
    right: 5%;
    font-size: 2em;
}

.user-game__modal__text,
.user-game__modal__title,
.user-game__modal__summary,
.user-game__modal__rank,
.user-game__modal__date,
.user-game__modal__rating {
    color: white;
}

.user-game__modal__flex,
.user-game__modal__text-flex {
    display: flex;
    justify-content: space-around;
}

.user-game__modal__text-container {
    margin-top: 2%;
    width: 76%;
}

.user-game__modal__summary {
    font-size: 1.25em;
}

.user-game__modal__text-flex {
    display: flex;
    justify-content: space-between;
    width: 62%;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25em;
}

.user-game__modal__flex {
    margin-top: 5%;
    width: 90%
}

.user-game__modal__img {
    width: 60%;
}

#user-game__modal {
    overflow-y: scroll;
}

.user-game__no-results {
    text-align: center;
    width: 100%;
}

.user-game__nogame-container {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.Toastify__toast-container--top-center {
    width: 20%;
}

/* @media (min-width: 768px) and (max-width: 1439px) {
    .profile-results {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .user-game {
        width: 50%;
    }

    #user-game__modal {
        top: 1%;
        left: 7%;
        width: 85%;
    }

    .user-game__modal__text-container {
        width: 100%;
    }

    .user-game__modal__flex {
        flex-direction: column;
        width: 100%;
    }

    .user-game__modal__summary {
        width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
} */

/* @media (min-width: 375px) and (max-width: 767px) {
    .profile-results {
        flex-direction: column;
    }

    .user-game {
        width: 100%;
    }

    .user-game__img img {
        width: 100%;
    }

    #user-game__modal {
        width: 100%;
        height: 100vh;
        left: 0%;
        top: 0%;
    }

    .user-game__modal__img {
        width: 90%;
    }

    .user-game__modal__flex {
        flex-direction: column;
        width: 100%;
    }

    .user-game__modal__text-container {
        width: 100%;
    }

    .user-game__modal__summary {
        font-size: 1.15em;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }
} */