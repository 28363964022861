.gamedata__row-status {
    font-weight: bold;
}

tr:nth-child(odd) {
    background-color: #d1cfc9; /* Light color */
}

#game-data__simplfied-flex {
    display: flex;
    width: 29%;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: auto;
}

.game-data__simplfied-flex-item {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
}

#game-data__more-details, #game-data__less-details {
    text-align: right;
    margin-right: 5%;
    text-decoration: underline;
    color: blue;
}

#game-data__more-details:hover, #game-data__less-details:hover {
    cursor: pointer;
}

@media (min-width: 768px) {
    #game-data__detailed {
        width: 50%;
        margin-left: auto;
    }
}

@media (min-width: 300px) and (max-width: 767px) {
    td, th {
        font-size: 0.75em;
    }

    #game-data__simplfied-flex {
        width: 100%;
    }
}