#dropdown-basic {
    padding: 0 10px;
}

#user-settings__gear {
    font-size: 2em;
}

.delete-btns-container {
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
}

#delete-modal__title {
    text-align: center;
    width: 100%;
}

.gameslist-games-container {
    position: relative;
}

.gameslist-games {
    display: flex;
    flex-wrap: wrap;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-around;
}

.gameslist-results__pages {
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.gameslist-results__pages__nav {
    width: 9%;
}

.gameslist-results__pages__nav:hover {
    cursor: pointer;
}

.gameslist-results__pages__num {
    margin-left: 10%;
    margin-right: 10%;
    width: 10%;
    text-align: center;
    font-size: 2em;
}

.gameslist-game {
    /* margin-left: 2%;
    margin-right: 2%; */
}

.gameslist-game__img:hover {
    cursor: pointer;
}

.gameslist-game__img,
.gameslist__search-game img {
    width: 390px;
    height: 220px;
}

.gameslist__game-info-flex {
    display: flex;
    justify-content: space-around;
}

.gameslist-game__date-container {
    margin-top: 8%;
}

.gameslist-game__rating {
    margin-top: 8%;
}

.gameslist-game__detail {
    margin-bottom: 0;
}

#gameslist-game-flex-container {
    justify-content: space-around;
}

.gameslist-game__summary {
    resize: none;
    margin-bottom: 5%;
}

.gameslist-game textarea,
.gameslist__search-game textarea {
    margin-top: 4%;
    height: 100px;
    width: 100%;
}

.gameslist-btn-container {
    display: flex;
    justify-content: space-around;
}

.gameslist-game__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 378px;
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 90px;
}

.gameslist-game__add-btn {
    background-color: #8E43FC;
    color: white;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 2px 3px 5px 2px #313030;
    width: 46%;
}

.gameslist-game p:hover {
    cursor: pointer;
}

.gameslist-games__update {
    background-color: rgba(0,0,0, 0.75);
    position: fixed;
    left: 41%;
    top: 53%;
    width: 300px;
    color: white;
    height: 100px;
    border-radius: 6px;
    padding: 1%;
}

.gameslist-games__update p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.gameslist-games__update__close {
    position: absolute;
    right: 2%;
    font-size: 1.5em;
    top: 0%;
    font-weight: bold;
    cursor: pointer;
}

.gameslist-game__no-results {
    text-align: center;
    width: 100%;
}

.gameslist__game-img,
.gameslist-game-summary {
    width: 100%;
}

.gameslist-game-summary {
    height: 100px;
}

#discover {
    max-width: 80%;
}

#discover__title-flex {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#discover__title-flex button {
    align-self: center;
}

.modal-btn {
    width: 30%;
    box-shadow: initial;
    padding: 11px 5px;
}

.modal-btn:hover {
    cursor: pointer;
}