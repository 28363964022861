.feedback-form {
    display: flex;
    flex-direction: column;
    width: 25%;
    margin-left: auto;
    margin-right: auto;
}

.topic-flex {
    display: flex;
    justify-content: space-around;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

.feedback-field {
    margin-top: 2.5%;
    margin-bottom: 2.5%;
}

#feedback-username {
    height: 30px;
    padding-left: 8px;
}

.feedback-form textarea {
    height: 100px;
    padding-left: 8px;
}

#feedback-btn {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    padding: 9px 10px;
    font-size: 1.25em;
}