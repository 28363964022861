@font-face {
    font-family: vt323;
    src: url("../../assets/fonts/VT323-Regular.ttf");
}

@font-face {
    font-family: alk;
    src: url("../../assets/fonts/Alkhemikal.ttf");
}

.vh-logo {
    width: 36%;
    margin-top: 9%;
}

.splash-container {
    background-position-x: center;
    background-position-y: 0;
    background-size: cover;
}

.header-container {
    background-image: url("../../assets/vh_blank_scroll.png");
    background-repeat: no-repeat;
    background-size: 60%;
    height: 100%;
    background-position: 50% 29%;
    position: relative;
}

.header-flex {
    /* position: absolute;
    left: 3%; */
}

.header__cta {
    font-family: alk;
    color: #6C3F29;
    font-size: 3em;
}

@media (max-width: 1440px) and (min-width: 1281px) {
    .splash-container {
        background-position-y: 42%;
    }

    .header-container {
        background-size: 67%;
    }

    .header-flex {
        left: 0;
        top: 7%;
    }

    .vh-logo {
        width: 40%;
    }

    .header__cta {
        font-size: 2.5em;
    }

    .vh__login-btn {
        font-size: 1.25em;
    }
}

@media (max-width: 1280px) and (min-width: 1181px) {
    .header-container {
        background-size: 80%;
    }

    .splash-container {
        background-position-y: 37%;
    }

    .header-flex {
        left: 0;
        top: 8%;
    }

    .vh-logo {
        width: 47%;
    }
}

@media (max-width: 1180px) and (min-width: 981px) {
    .splash-container {
        background-position-y: 29%;
    }

    .header-container {
        background-size: 92%;
    }

    .header__cta {
        font-size: 2.75em;
    }

    .vh-logo {
        width: 54%;
    }
}

@media (max-width: 981px) and (min-width: 881px) {
    .header-container {
        background-size: 100%;
    }

    .vh-logo {
        width: 63%;
        margin-top: 12%;
    }

    .header__cta {
        font-size: 2.5em;
    }
}

@media (max-width: 880px) and (min-width: 769px) {
    .header-container {
        background-size: 100%;
    }

    .vh-logo {
        width: 63%;
        margin-top: 15%;
    }

    .header-flex {
        left: 0;
        top: 9%;
    }

    .header__cta {
        font-size: 2.25em;
    }
}

@media (max-width: 768px) {
    .header-container {
        background-size: 100%;
    }

    .vh-logo {
        width: 63%;
        margin-top: 17%;
    }

    .header-flex {
        left: 0;
        top: 5%;
    }

    .header__cta {
        font-size: 2em;
    }
}

@media (max-width: 634px) {
    .header-container {
        position: absolute;
    }

    .header-flex {
        position: relative;
        top: 14%;
    }

    .header__cta {
        font-size: 1.75em;
    }

    .vh-logo {
        margin-top: 5%;
    }
}

@media (max-width: 500px) {
    .header-container {
        background-image: url("../../assets/vh_vertical_scroll2.png");
    }

    .header-flex {
        display: flex;
        flex-direction: column;
        height: 59%;
        padding-top: 19%;
    }

    .vh-logo {
        width: 69%;
        align-self: center;
    }

    .header__cta {
        width: 50%;
        margin: 0 auto;
        font-size: 1.5em;
    }
}