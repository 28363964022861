.find-user__results {
    display: flex;
    justify-content: space-around;
}

.find-results__pages {
    position: fixed;
    bottom: 0;
}

.find-results__pages__nav {
    width: 15%;
}

.find-results__pages__nav:hover {
    cursor: pointer;
}

.find-user__pages {
    margin-left: 9%;
    margin-right: 9%;
    width: 15%;
    text-align: center;
}

.found-user__btn {
    color: white;
    background-color: #8E43FC;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 2px 3px 5px 2px #313030;
}

.found-user__link {
    text-decoration: none;
}

.find-user__head {
    font-size: 1.5em;
}

#free-solo-2-demo {
    z-index: 1;
}