@font-face {
    font-family: vt323;
    src: url("../../assets/fonts/VT323-Regular.ttf");
}

.vh__login-btn {
    font-family: vt323;
    background-color: #8E43FC;
    color: white;
    border-radius: 4px;
    box-shadow: 2px 3px 5px 2px #313030;
    border: none;
    font-size: 1.5em;
}

.header__logo-img {
    background-color: white;
    width: 50px;
}

.vh__login-btn__text {
    padding-left: 16px;
    padding-right: 16px;
}

@media (max-width: 768px) {
    .vh__login-btn {
        font-size: 1.25em;
    }
}